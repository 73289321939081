import React from 'react'

export const AboutMock = () => {
  return (
    <div className="about-container">
      <p>
        A nossa história começa em 1924! Quando Leonardo Valente Hyczy deu
        início ao comércio de um pequeno biscoito especial que logo fez sucesso
        dentro fora de Guarapuava, no Paraná.
      </p>
      <p>
        Devido ao grande sucesso, em 1937 é fundada a panificadora Triunfante,
        que posteriormente deu o ponta pé para a criação do Grupo Superpão, como
        a rede de supermercados que conhecemos hoje.
      </p>
      <p>
        No ano de 2022, completamos 98 anos de história. Neste tempo passamos a
        contar com lojas espalhadas por todo o estado do Paraná e norte de Santa
        Catarina, trabalhando com 5 bandeiras, sendo elas: Superpão, Compre
        Mais, Germania, Super Baratão e Oba. Além disso, o Grupo Superpão atua
        em outros segmentos como Posto de Combustível localizados nos estados do
        Paraná e Santa Catarina, gerando emprego e renda para os mais de 4.6 mil
        colaboradores.
      </p>
      <p>
        Em 2021 um novo capítulo desta trajetória começou a ser escrita. A
        parceria com o Grupo Pátria Investimentos trouxe ao grupo novas
        possibilidades, entre elas a aquisição da rede de Supermercados
        Germânia, que passou a fazer parte da nossa história no mesmo ano. No
        ano de 2022, o Grupo Superpão passa a contar com mais de 39 lojas
        espalhadas por todo o Paraná e Santa Catarina, 5 Centros de
        Distribuição, com um foco total na modernização da companhia,
        trabalhando com um mix de mais de 17 mil produtos, 698 PDVS (caixas) e
        mais de 148 mil metros quadrados de área de vendas. Sendo, a inovação
        nosso carro-chefe, através da digitalização da companhia trazendo mais
        dinamismo e velocidade ao nosso dia a dia.
      </p>
      <p>
        Com uma gestão comercial cada vez mais profissionalizada, uma logística
        moderna e veloz, uma comunicação mais ampla mas sem perder a proximidade
        com nossos consumidores, buscamos ir além do mercado físico e nos tornar
        uma das maiores plataformas de e-commerce da região sul do país.
      </p>
      <p>
        Vamos juntos com a gente. O superpão segue escrevendo esta história com
        você!
      </p>
    </div>
  )
}
